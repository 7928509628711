import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, AlertDescription, AlertTitle, Divider } from '@chakra-ui/react';
import { DescriptionList, DescriptionListItem, DescriptionListTerm } from '@netiva/classifieds-ui';

import { PrintPriceSummary } from '@/components/ui';
import { useDate } from '@/hooks';
import { useAppSelector } from '@/store';

export const EInseratReview: FunctionComponent = () => {
  const { price, selectedIssueDates } = useAppSelector((state) => state.ad);
  const { t } = useTranslation();
  const { formatDate } = useDate();

  return (
    <>
      <DescriptionList variant="alignRight">
        <DescriptionListTerm>{t('ad.steps.review.issueDates')}</DescriptionListTerm>
        {selectedIssueDates.map((date) => (
          <DescriptionListItem key={date}>{formatDate(date)}</DescriptionListItem>
        ))}
      </DescriptionList>
      <Divider my={4} />
      <PrintPriceSummary price={price} issueCount={selectedIssueDates.length} showMultipleIssues />
      <Alert status="info" flexDirection="column">
        <AlertTitle>{t('ad.steps.review.guidelinesMessage.title')}</AlertTitle>
        <AlertDescription dangerouslySetInnerHTML={{ __html: t('ad.steps.review.guidelinesMessage.text') }} />
      </Alert>
    </>
  );
};
