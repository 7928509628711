import { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  Flex,
  Image,
  StackDivider,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { accountApi } from '@netiva/classifieds-api';
import { getQueryValue, parseQueryValue } from '@netiva/classifieds-common';
import { Loader, SelectableTag } from '@netiva/classifieds-ui';

import { PrintPriceSummary } from '@/components/ui';
import { useDate } from '@/hooks';
import { routes } from '@/lib/routes';

export const PrintQuotePage: FC = memo(function PrintQuotePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const dataObjectId = parseQueryValue(params.dataObjectId, parseInt);
  const token = getQueryValue(searchParams.get('token'));
  const { formatDate } = useDate();
  const toast = useToast();

  const { data: quoteData, isLoading: isLoadingContract } = accountApi.useGetPrintQuote(
    dataObjectId ? { dataObjectId: dataObjectId!, token } : skipToken
  );
  const { data: issueDates, isLoading: isLoadingIssueDates } = accountApi.useGetPrintAdvertIssueDates(
    dataObjectId ? { dataObjectId: dataObjectId } : skipToken
  );
  const [acceptQuote, { isLoading: isSubmitting }] = accountApi.useAcceptPrintQuote();

  const [selectedIssueDates, setSelectedIssueDates] = useState<string[]>([]);
  const [tosChecked, setTosChecked] = useState(false);

  const isActive = (issueDate: string) => {
    return selectedIssueDates.includes(issueDate);
  };

  const handleSelectIssueDate = (date: string) => {
    const newIssueDates = isActive(date)
      ? selectedIssueDates.filter((item) => item !== date)
      : [...selectedIssueDates, date];
    setSelectedIssueDates(newIssueDates);
  };

  const handleSubmit = async () => {
    if (!quoteData?.contract.id) {
      return;
    }

    if (selectedIssueDates.length === 0) {
      toast({
        status: 'error',
        description: t('printQuote.error.selectIssueDate'),
        isClosable: true,
        duration: 5000,
      });
      return;
    }

    if (!tosChecked) {
      toast({
        status: 'error',
        description: t('ad.steps.review.submitTosError'),
        isClosable: true,
        duration: 5000,
      });
      return;
    }
    try {
      await acceptQuote({ contractId: quoteData.contract.id, issueDates: selectedIssueDates, token }).unwrap();
    } catch (err) {
      console.error(err);
      toast({ status: 'error', description: t('printQuote.error.submit'), isClosable: true });
      return;
    }

    navigate(routes.paymentOverview(quoteData.contract.id, token));
  };

  const isLoading = isLoadingContract || isLoadingIssueDates;

  return (
    <Box position="relative">
      <Loader isLoading={isLoading} />
      <VStack align="stretch" divider={<StackDivider />} spacing={4}>
        <Box maxWidth="500px">
          <Text>TODO: object details?</Text>
          {/* <AttributeDisplay ... */}
          <Image src={quoteData?.imageUrl || ''} maxWidth="500px" border="default" boxShadow="default" />
        </Box>
        <Box>
          <Text>TODO: select issue dates title</Text>
          <Flex wrap="wrap" gap={2}>
            {issueDates?.dates.map((date) => (
              <SelectableTag key={date} isSelected={isActive(date)} onClick={() => handleSelectIssueDate(date)}>
                {formatDate(date)}
              </SelectableTag>
            ))}
          </Flex>
        </Box>
        <Box maxWidth="500px">
          <Text>TODO: price title</Text>
          <PrintPriceSummary
            price={quoteData?.price}
            issueCount={selectedIssueDates.length}
            showMultipleIssues
            unified
          />
        </Box>
        <Flex direction="column" align="flex-start" gap={4}>
          <Alert status="info" flexDirection="column">
            <AlertTitle>{t('ad.steps.review.guidelinesMessage.title')}</AlertTitle>
            <AlertDescription dangerouslySetInnerHTML={{ __html: t('ad.steps.review.guidelinesMessage.text') }} />
          </Alert>
          <Box>
            <Checkbox isChecked={tosChecked} onChange={(e) => setTosChecked(e.target.checked)} mt={4} isRequired>
              <span dangerouslySetInnerHTML={{ __html: t('ad.steps.review.tosText') }}></span>
            </Checkbox>
          </Box>
          <Button
            onClick={handleSubmit}
            disabled={isSubmitting}
            isLoading={isSubmitting}
            colorScheme={tosChecked && selectedIssueDates.length ? undefined : 'neutral'}
          >
            {t('ad.steps.review.submit')}
          </Button>
        </Flex>
      </VStack>
    </Box>
  );
});
